import React, { useEffect, useState } from 'react';
import AuthProvider from '../utils/AuthProvider';
import axios from 'axios';
import ENV from '../utils/Constant';
import { useNavigate } from 'react-router-dom';
import {Button, Form, Modal} from "react-bootstrap";
import {toast} from "react-toastify";

const SeatAllocation = () => {
    const [selectedCadre, setSelectedCadre] = useState('');
    const [selectedDesignation, setSelectedDesignation] = useState('');
    const [vacancy, setVacancy] = useState(null);
    const [newvacancy, setnewVacancy] = useState(null);
    const [carryForwardsCount, setCarryForwardsCount] = useState(null);
    const [totalDesignationSanctionedStrength, settotalDesignationSanctionedStrength] = useState(null);
    const apiUrl = ENV.baseUrl;
    const [loading, setLoading] = useState(false);
    const [DisableButton, setDisableButton] = useState(true);
    const [error, setError] = useState(null);
    const [cadreResponse, setcadreResponse] = useState([]);
    const navigate = useNavigate();
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedFieldId, setSelectedFieldId] = useState('');
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [additionalFields, setAdditionalFields] = useState([]);
    const [dropdownCategoryOptions, setdropdownCategoryOptions] = useState([]);
    const [Count, setCount] = useState(0);
    const [designationID, setDesignationID] = useState(0);
    const [AddCadreVerticalReservation, setAddCadreVerticalReservation] = useState(true);
    const [cadreTotalSanctionedStrength, setCadreTotalSanctionedStrength] = useState(0);
    const [designations, setDesignations] = useState([]);
    const [carryForwardValue, setCarryForwardValue] = useState(); // To store the carry-forward value
    const [carryForward, setCarryForward] = useState([]);
    const [verticalReservation, setVerticalReservations] = useState(null);
    const [horizontalReservation, setHorizontalReservations] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

                const response = await axios.get(`${apiUrl}/cadres/cadre-with-designations-and-carry-forward`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });

                // console.log("response", response.data);
                setcadreResponse(response.data)
                setError(null);
            } catch (error) {
                console.error('Error fetching vertical reservations:', error);
                setError('Error fetching vertical reservations. Please try again.');
            }
        };

        fetchData();
    }, [apiUrl]);

    useEffect(() => {
        const fetchVerticalReservation = async () => {
            try {
                const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

                const response = await axios.get(`${apiUrl}/vertical-reservations`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });

                // setVerticalReservations(response.data);
                // console.log(response.data);
                setDropdownOptions(response.data);
                setVerticalReservations(response.data)
                setLoading(false);
                setError(null);
            } catch (error) {
                console.error('Error fetching vertical reservations:', error);
                setError('Error fetching vertical reservations. Please try again.');
                setLoading(false);
            }
        };

        fetchVerticalReservation();
    }, [apiUrl]);

    useEffect(() => {
        const fetchHorizontalReservations = async () => {
            setLoading(true);
            try {
                const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

                const response = await axios.get(`${apiUrl}/horizontal-reservations`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });

                // setHorizontalReservations(response.data);
                setdropdownCategoryOptions(response.data);
                setHorizontalReservations(response.data);
                // console.log(response.data);
                // setLoading(false);
                // setError(null);
            } catch (error) {
                console.error('Error fetching horizontal reservations:', error);
                setError('Error fetching horizontal reservations. Please try again.');
                setLoading(false);
            }
        };

        fetchHorizontalReservations();
    }, [apiUrl]);


    // Handle cadre selection
    const handleCadreChange = (e) => {
        const selected = e.target.value;
        setSelectedCadre(selected);
        setSelectedDesignation(''); // Reset designation when cadre changes
        setVacancy(null);
        setCarryForwardsCount(null);
        setDisableButton(true)

    };

    const handleFieldChange = (index, field, value) => {
        const updatedFields = [...additionalFields];
        updatedFields[index][field] = value;
        setAdditionalFields(updatedFields);

        if (field === 'hrcategoryId') {
            const updatedOptions = dropdownCategoryOptions.filter(option => option.categoryId !== value);
            setdropdownCategoryOptions(updatedOptions);
        }
    };

    const handleAddField = () => {
        setAdditionalFields([...additionalFields, { id: Count, hrcategoryId: '', cfValue: '' }]);
        setCount(Count + 1)
    };

    // Handle designation selection
    const handleDesignationChange = (e) => {
        const designationId = e.target.value;
        const cadre = cadreResponse.find((cadre) => cadre.cadreId === parseInt(selectedCadre));
        const designation = cadre?.designations.find((des) => des.designationId === parseInt(designationId));
        let totalCount = 0;
        setDisableButton(false)

        designation.carryForwards?.forEach(carryForward => {
            totalCount += carryForward.cfValue;
        });
        console.log(designation);

        setSelectedDesignation(designationId);
        setDesignationID(designationId);
        setVacancy(designation?.vacancy);
        setCarryForwardsCount(totalCount);
        settotalDesignationSanctionedStrength(designation?.totalDesignationSanctionedStrength)
    };

    const SaveDesignation = async () => {
        console.log("CF")
        if (!designationID) {
            console.log("designationID", designationID);
            toast.error('Designation ID is required.');
            return;
        }

        if (!selectedFieldId) {
            toast.error('Vertical Reservation Category is required.');
            return;
        }

        if (additionalFields.length === 0) {
            toast.error('At least one horizontal reservation field is required.');
            return;
        }
        try {
            const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

            // Convert data format to [designationId, vrcategoryId, hrcategoryId, cfValue]
            const transformedData = additionalFields.map(field => ({
                designationId: parseInt(designationID, 10), // Ensure designationID is an integer
                vrcategoryId: parseInt(selectedFieldId, 10), // Ensure selectedFieldId is an integer
                hrcategoryId: parseInt(field.hrcategoryId, 10), // Convert hrcategoryId to integer
                cfValue: parseFloat(field.cfValue) // Convert cfValue to a number (float)
            }));

            console.log(transformedData);

            const response = await axios.post(`${apiUrl}/carry-forward/create-carry-forward_for_user_input_vacancy`, transformedData, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                }
            });
            fetchCarryForward(designationID);
            setCarryForwardValue(true);
            setShowAddModal(false);
            toast.success('Carray Forward added successfully!');
        } catch (error) {
            console.error('Error adding designation:', error.response ? error.response.data : error.message);
            toast.error('Error adding designation. Please try again.');
        }
    }


    const SaveAndCreateDesignation = async () => {
        // console.log(selectedFieldId, 'selectedFieldId');
        // console.log('additionalFields', additionalFields);
        // console.log('designationID', designationID);
        // const requestData = {
        //     verticalReservationId: selectedFieldId,       // selectedFieldId
        //     additionalFields: additionalFields, // additionalFields array
        //     designationId: designationID    // designationID
        // };

        // console.log(requestData)
        if (!designationID) {
            toast.error('Designation ID is required.');
            return;
        }

        if (!selectedFieldId) {
            toast.error('Vertical Reservation Category is required.');
            return;
        }

        if (additionalFields.length === 0) {
            toast.error('At least one horizontal reservation field is required.');
            return;
        }
        try {
            const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

            const transformedData = additionalFields.map(field => ({
                designationId: parseInt(designationID, 10), // Ensure designationID is an integer
                vrcategoryId: parseInt(selectedFieldId, 10), // Ensure selectedFieldId is an integer
                hrcategoryId: parseInt(field.hrcategoryId, 10), // Convert hrcategoryId to integer
                cfValue: parseFloat(field.cfValue) // Convert cfValue to a number (float)
            }));

            const response = await axios.post(`${apiUrl}/carry-forward/create-carry-forward_for_user_input_vacancy`, transformedData, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                }
            });

            setCount(0)
            setSelectedFieldId('')
            setAdditionalFields([])
            toast.success('Designation added successfully!');
        } catch (error) {
            console.error('Error adding designation:', error.response ? error.response.data : error.message);
            toast.error('Error adding designation . Please try again.');
        }

    }

    const handleVacancyChange = (e) => {
        const value = e.target.value;
            setError(null); // Clear the error if validation passes
            setDisableButton(false); // Enable the button
            setnewVacancy(value);
    };

    const handleViewDesignations = async (e) => {
        console.log("Hello Calculator");
        e.preventDefault();
    
        // Retrieve the JWT token
        const token = AuthProvider.getToken();
        setLoading(true);
    
        try {
            // Prepare the request payload
            const transformedData = {
                cadreId: parseInt(selectedCadre),
                designationId: parseInt(selectedDesignation),
                vacancy:parseInt(newvacancy)
            };
            console.log("Request Payload:", transformedData);
    
            // Send the request to the backend
            const response = await axios.get(`${apiUrl}/reservations/allocate-reservation`, {
                params: {
                    designationId: transformedData.designationId,
                    vacancy: transformedData.vacancy,
                },
                headers: {
                    Authorization: `Bearer ${token}`, // Include the JWT token in the Authorization header
                },
            });

            console.log(response.data);
            
    
            if (response.status === 200) {
                console.log("API Response:", response.data);
    
                navigate(`/seat-allocation-result`, {
                    state: {
                        results: response.data,
                    },
                });
            }
        } catch (error) {
            console.error("Error fetching seat allocation data:", error);
    
            // Handle errors gracefully (e.g., show an error message to the user)
            alert("Failed to fetch seat allocation data. Please try again.");
        } finally {
            setLoading(false);
        }
    };
    

    const fetchCarryForward = async (designationId) => {
        try {
            const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider
            const response = await axios.get(`${apiUrl}/carry-forward/get-carry-forward-user-input-vacancy-by-designationId/${designationId}`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                }
            });
            console.log(response.data)
            setCarryForward(response.data)
           // setdata(response.data); // Update the state with the fetched carry-forward data
        } catch (error) {
            console.error('Error fetching carry-forward:', error);
        }
    };

    return (
        <div className="md:p-4 p-2 w-full">
  <div className="max-w-xl mx-auto">
    <h2 className="text-xl pt-2 md:pt-0 text-center">Reservation Calculator</h2>

    <form
      onSubmit={handleViewDesignations}
      className="flex flex-col p-4 bg-white shadow-md rounded-md max-w-xl mx-auto gap-4"
    >
      {/* Cadre Selection */}
      <div className="flex flex-col gap-2">
        <label htmlFor="cadre" className="text-lg font-bold">
          Select Cadre:
        </label>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          id="cadre"
          value={selectedCadre}
          onChange={handleCadreChange}
        >
          <option value="">Select Cadre</option>
          {cadreResponse.map((cadre) => (
            <option key={cadre.cadreId} value={cadre.cadreId}>
              {cadre.cadreName}
            </option>
          ))}
        </select>
      </div>

      {/* Designation Selection */}
      <div className="flex flex-col gap-2">
        <label htmlFor="designation" className="text-lg font-bold">
          Select Designation:
        </label>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          id="designation"
          value={selectedDesignation}
          onChange={handleDesignationChange}
          disabled={!selectedCadre}
        >
          <option value="">Select Designation</option>
          {selectedCadre &&
            cadreResponse
              .find((cadre) => cadre.cadreId === parseInt(selectedCadre))
              ?.designations.map((designation) => (
                <option key={designation.designationId} value={designation.designationId}>
                  {designation.designationName}
                </option>
              ))}
        </select>
      </div>

      {/* Input for Vacancy */}
      {selectedDesignation && (
        <div className="flex flex-col gap-2">
          <label htmlFor="vacancy" className="text-lg font-bold">
            Enter Vacancy:
          </label>
          <input
            type="number"
            id="newvacancy"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={newvacancy}
            onChange={handleVacancyChange}
            placeholder="Enter vacancy count"
          />
          {!carryForwardValue && (
            <button
              type="button"
              className="btn btn-primary py-2 rounded-md text-white bg-blue-500 hover:bg-blue-600"
              onClick={() => setShowAddModal(true)}
            >
              Add Carry Forward
            </button>
          )}
          {carryForward && carryForward.length > 0 && (
            <table className="min-w-full table-auto bg-green-100 text-green-800 p-2 rounded-md text-xs">
              <thead>
                <tr>
                  <th className="px-4 py-2">Vertical Reservation</th>
                  <th className="px-4 py-2">Horizontal Reservation</th>
                  <th className="px-4 py-2">Carry Forward Value</th>
                </tr>
              </thead>
              <tbody>
                {carryForward.map((cf, index) => (
                  <tr key={index} className="border-t text-xs">
                    <td className="border px-4 py-2">{cf.verticalReservationCategory}</td>
                    <td className="border px-4 py-2">{cf.horizontalReservationCategory}</td>
                    <td className="border px-4 py-2">{cf.cfValue}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {error && <p className="text-red-500 text-sm">{error}</p>}
        </div>
      )}

      {/* Calculate Button */}
      <button
        disabled={DisableButton}
        className="disabled:bg-gray-400 disabled:text-gray-100 btn btn-success py-2 rounded-md"
        type="submit"
      >
        {loading ? (
          <div className="flex items-center w-full justify-center">
            <div className="custom-loader"></div>
          </div>
        ) : (
          "Calculate"
        )}
      </button>
    </form>
  </div>

  {/* Modal */}
  <Modal show={showAddModal} onHide={() => setShowAddModal(false)} centered>
    <Modal.Header closeButton>
      <Modal.Title>Add Carry Forward</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group className="mb-3" controlId="formAdditionalFields">
          <Form.Label>Select Category</Form.Label>
          <Form.Control
            as="select"
            value={selectedFieldId}
            onChange={(e) => setSelectedFieldId(e.target.value)}
          >
            <option value="">Select an option</option>
            {dropdownOptions.map((option) => (
              <option key={option.categoryId} value={option.categoryId}>
                {option.categoryName}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {additionalFields.map((field, index) => (
          <div key={index} className="mb-3 flex flex-wrap gap-2">
            <Form.Group className="mb-3 flex-grow" controlId={`formAdditionalFieldCategory${index}`}>
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                as="select"
                value={field.name}
                onChange={(e) => handleFieldChange(index, "hrcategoryId", e.target.value)}
              >
                <option value="">Select an option</option>
                {dropdownCategoryOptions.map((option) => (
                  <option key={option.categoryId} value={option.categoryId}>
                    {option.categoryName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 w-1/4" controlId="formAdditionalFields">
              <Form.Label>Value</Form.Label>
              <Form.Control
                type="text"
                placeholder="Value"
                value={field.value}
                onChange={(e) => handleFieldChange(index, "cfValue", e.target.value)}
              />
            </Form.Group>
          </div>
        ))}
        <Button variant="outline-primary" onClick={handleAddField}>
          Add Field
        </Button>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setShowAddModal(false)}>
        Close
      </Button>
      <Button variant="success" onClick={SaveDesignation}>
        Save
      </Button>
      <Button variant="success" onClick={SaveAndCreateDesignation}>
        Save and Create Another
      </Button>
    </Modal.Footer>
  </Modal>
</div>

    );
};

export default SeatAllocation;
