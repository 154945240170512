import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Spinner, Alert, Table, Button } from 'react-bootstrap';
import ENV from "../utils/Constant";
import AuthProvider from "../utils/AuthProvider";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { ArrowLeftCircle } from "react-bootstrap-icons";

const ReservationResult = () => {
    const navigate = useNavigate();
    const apiUrl = ENV.baseUrl;
    const { designationId } = useParams();
    const [reservationData, setReservationData] = useState(null);
    const [designationName, setDesignationName] = useState(''); // State for designation name
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalPosts, setTotalPosts] = useState(0); // Use state to manage totalPosts

    useEffect(() => {
        const fetchReservationData = async () => {
            try {
                const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider
                // Fetch reservation data
                const response = await axios.get(`${apiUrl}/reservations/calculate-seat-reservation/${designationId}`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });

                // const res = await axios.get(`${apiUrl}/reservations/calculate-reservation/${designationId}`, {
                //     headers: {
                //         Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                //     }
                // });

                setReservationData(response.data);
                // console.log("res", response);

                // Fetch designation name
                const designationResponse = await axios.get(`${apiUrl}/designations/designationName/${designationId}`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });
                setDesignationName(designationResponse.data); // Assuming the response has a `name` field

                toast.success('Reservation data fetched successfully!');
            } catch (error) {
                console.error('Error fetching reservation data:', error.response ? error.response.data : error.message);
                setError('Error fetching reservation data. Please try again.');
                toast.error('Error fetching reservation data. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchReservationData();
    }, [designationId, apiUrl]);


    const calculateTotalPosts = () => {
        if (!reservationData || !reservationData["Vertical and Horizontal Reservation Calculations"]) {
            return 0;
        }

        const calculations = reservationData["Vertical and Horizontal Reservation Calculations"];

        if (!Array.isArray(calculations)) {
            return 0;
        }

        let total = 0;

        // Sum reserved and general seats
        calculations.forEach(category => {
            if (category["Reserved Seats"]) {
                total += category["Reserved Seats"];
            }
            if (category["General Seats"]) {
                total += category["General Seats"];
            }
            if (category["Horizontal Reservations"]) {
                category["Horizontal Reservations"].forEach(hr => {
                    if (hr["Horizontal Reserved Seats"]) {
                        total += hr["Horizontal Reserved Seats"];
                    }
                });
            }
        });

        return total;
    };

    const renderReservationData = () => {
        if (!reservationData || !reservationData["Vertical and Horizontal Reservation Calculations"]) {
            return <p>No reservation data available.</p>;
        }

        const calculations = reservationData["Vertical and Horizontal Reservation Calculations"];
        console.log("calculations", calculations);

        if (!Array.isArray(calculations)) {
            return <p>Invalid reservation data format.</p>;
        }

        let serialNo = 1;

        // Store rows here
        const rows = [];

        // Add "Unreserved (Reserved)" entries first
        calculations.forEach((category, index) => {
            const {
                "Category Name": categoryName,
                "Reserved Seats": reservedSeats
            } = category;

            if (reservedSeats > 0) {
                rows.push(
                    <tr key={`${index}-reserved`} style={{ backgroundColor: '#f8f9fa', fontWeight: 'bold' }}>
                        <td>{serialNo++}</td>
                        <td>{`Unreserved (${categoryName})`}</td>
                        <td>{reservedSeats}</td>
                    </tr>
                );
            }
        });

        // Add General Seats
        calculations.forEach((category, index) => {
            const {
                "Category Name": categoryName,
                "General Seats": generalSeats
            } = category;

            if (generalSeats > 0) {
                rows.push(
                    <tr key={`${index}-general`}>
                        <td>{serialNo++}</td>
                        <td>{`${categoryName} (General)`}</td>
                        <td>{generalSeats}</td>
                    </tr>
                );
            }
        });

        // Add Horizontal Reservations
        calculations.forEach((category, index) => {
            const {
                "Category Name": categoryName,
                "Horizontal Reservations": horizontalReservations
            } = category;

            if (horizontalReservations) {
                horizontalReservations
                    .filter(hr => hr["Horizontal Reserved Seats"] > 0)
                    .forEach(hr => {
                        rows.push(
                            <tr key={`${index}-horizontal-${hr["Category Name"]}`}>
                                <td>{serialNo++}</td>
                                <td>{`${categoryName} (${hr["Category Name"]})`}</td>
                                <td>{hr["Horizontal Reserved Seats"]}</td>
                            </tr>
                        );
                    });
            }
        });

        return rows;
    };

    useEffect(() => {
        if (!loading) {
            setTotalPosts(calculateTotalPosts());
        }
    }, [loading, reservationData]);

    if (loading) {
        return <Spinner animation="border" />;
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    const handleBackClick = () => {
        navigate(-1); // Navigate back in history
    };

    const buttonStyle = {
        backgroundColor: '#007bff', // Blue background
        color: '#fff', // White text
        border: 'none', // Remove border
        padding: '10px 20px', // Padding
        borderRadius: '5px', // Rounded corners
        cursor: 'pointer', // Pointer cursor on hover
        display: 'flex', // Flexbox for alignment
        alignItems: 'center', // Center items vertically
        fontSize: '16px', // Font size
        marginBottom: '20px' // Space between button and table
    };

    // Inline styles for the icon
    const iconStyle = {
        marginRight: '8px', // Space between icon and text
    };
    const handlePrint = () => {
        window.print();
    };
    return (

        <div className="md:p-4">
            <div className='flex gap-2'>
                <button onClick={handleBackClick} style={buttonStyle}>
                    <ArrowLeftCircle style={iconStyle} /> Go Back
                </button>
                <button onClick={handlePrint} style={buttonStyle}>
                    Print Table
                </button>
            </div>
            <h4 style={{ marginBottom: '20px' }}>Designation: {designationName || 'Loading...'}</h4>

            <Table striped bordered hover style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th style={{
                            padding: '8px',
                            textAlign: 'left',
                            borderBottom: '1px solid #ddd',
                            backgroundColor: '#f8f9fa',
                            color: '#343a40',
                            fontWeight: 'bold'
                        }}>Sl. No
                        </th>
                        <th style={{
                            padding: '8px',
                            textAlign: 'left',
                            borderBottom: '1px solid #ddd',
                            backgroundColor: '#f8f9fa',
                            color: '#343a40',
                            fontWeight: 'bold'
                        }}>Reservation Category
                        </th>
                        <th style={{
                            padding: '8px',
                            textAlign: 'left',
                            borderBottom: '1px solid #ddd',
                            backgroundColor: '#f8f9fa',
                            color: '#343a40',
                            fontWeight: 'bold'
                        }}>No. of Post
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {renderReservationData().map((row, index) => (
                        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                            {row.props.children.map((cell, cellIndex) => (
                                <td key={cellIndex}
                                    style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>
                                    {cell.props.children}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="2" style={{
                            padding: '8px',
                            textAlign: 'left',
                            borderBottom: '1px solid #ddd',
                            backgroundColor: '#f8f9fa',
                            fontWeight: 'bold'
                        }}>TOTAL
                        </td>
                        <td style={{
                            padding: '8px',
                            textAlign: 'left',
                            borderBottom: '1px solid #ddd',
                            backgroundColor: '#f8f9fa',
                            fontWeight: 'bold'
                        }}>{totalPosts}</td>
                    </tr>
                </tfoot>
            </Table>
        </div>
    );
};

export default ReservationResult;
