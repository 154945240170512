import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container, Row, Col, Table, Button, Modal, Form, Spinner, Alert
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import AuthProvider from "../utils/AuthProvider";
import ENV from "../utils/Constant";
import { FaPlus } from "react-icons/fa";

const VerticalReservationList = () => {
    const [verticalReservations, setVerticalReservations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showNewModal, setShowNewModal] = useState(false);
    const [selectedReservation, setSelectedReservation] = useState({});
    const apiUrl = ENV.baseUrl;
    const [newReservation, setNewReservation] = useState({
        categoryName: '',
        categoryPercentage: ''
    });
    const [newReservationErrors, setNewReservationErrors] = useState({
        categoryName: '',
        categoryPercentage: ''
    });

    const Userroles = localStorage.getItem('role')


    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

                const response = await axios.get(`${apiUrl}/vertical-reservations`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });

                setVerticalReservations(response.data);
                setLoading(false);
                setError(null);
            } catch (error) {
                console.error('Error fetching vertical reservations:', error);
                setError('Error fetching vertical reservations. Please try again.');
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]);



    const handleEditOpen = (reservation) => {
        setSelectedReservation(reservation);
        setShowEditModal(true);
    };

    const handleEditClose = () => {
        setShowEditModal(false);
    };

    const handleNewOpen = () => {
        setShowNewModal(true);
    };

    const handleNewClose = () => {
        setShowNewModal(false);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setSelectedReservation(prev => ({ ...prev, [name]: value }));
    };

    const handleNewChange = (e) => {
        const { name, value } = e.target;
        setNewReservation(prev => ({ ...prev, [name]: value }));
    };

    const validateNewReservation = () => {
        let isValid = true;
        let errors = {};

        if (!newReservation.categoryName.trim()) {
            errors.categoryName = 'Category Name is required';
            isValid = false;
        }

        if (!newReservation.categoryPercentage || isNaN(newReservation.categoryPercentage) || newReservation.categoryPercentage < 0 || newReservation.categoryPercentage > 100) {
            errors.categoryPercentage = 'Category Percentage must be a number between 0 and 100';
            isValid = false;
        }

        setNewReservationErrors(errors);
        return isValid;
    };

    const handleEditSubmit = async () => {
        try {
            const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

            await axios.put(`${apiUrl}/vertical-reservations/edit/${selectedReservation.categoryId}`, selectedReservation, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                }
            });

            setVerticalReservations(verticalReservations.map(reservation =>
                reservation.categoryId === selectedReservation.categoryId ? selectedReservation : reservation
            ));
            handleEditClose();
            toast.success('Reservation updated successfully!');
        } catch (error) {
            console.error('Error updating vertical reservation:', error);
            toast.error('Error updating reservation. Please try again.');
        }
    };


    const handleNewSubmit = async () => {
        if (validateNewReservation()) {
            try {
                const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

                const response = await axios.post(`${apiUrl}/vertical-reservations/create-vertical-reservation`, newReservation, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });

                setVerticalReservations([...verticalReservations, response.data]);
                setNewReservation({ categoryName: '', categoryPercentage: '' }); // Clear form after submission
                handleNewClose();
                toast.success('New reservation created successfully!');
            } catch (error) {
                console.error('Error creating new vertical reservation:', error);
                toast.error('Error creating reservation. Please try again.');
            }
        }
    };


    const handleDelete = async (id) => {
        try {
            const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

            await axios.delete(`${apiUrl}/vertical-reservations/delete/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                }
            });

            setVerticalReservations(verticalReservations.filter(reservation => reservation.categoryId !== id));
            toast.success('Reservation deleted successfully!');
        } catch (error) {
            console.error('Error deleting vertical reservation:', error);
            toast.error('Error deleting reservation. Please try again.');
        }
    };


    return (
        <div className='md:p-4'>
            <div className='flex flex-col pt-3 md:pt-0'>
                {Userroles === 'ROLE_ADMIN' && (
                    <button variant="primary" onClick={handleNewOpen} className='max-w-32 mb-2 flex items-center gap-2 rounded-md bg-success text-white px-3 py-2 cursor-pointer'>
                        <div className='flex items-center justify-center gap-2 text-white'>
                            <FaPlus /> New VR
                        </div>
                    </button>
                )}
                <h2 className='text-xl'>List of Vertical Reservations</h2>
            </div>
            {loading ? (
                <Spinner animation="border" />
            ) : error ? (
                <Alert variant="danger">
                    {error}
                </Alert>
            ) : (
                <div className='bg-white p-2 max-w-3xl rounded-md shadow-md' style={{ overflowX: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Category Name</th>
                                <th>Category Percentage (%)</th>
                                {Userroles === 'ROLE_ADMIN' && (

                                    <th>Actions</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {verticalReservations.map((reservation) => (
                                <tr key={reservation.categoryId}>
                                    <td>{reservation.categoryName}</td>
                                    <td>{reservation.categoryPercentage}%</td>
                                    {Userroles === 'ROLE_ADMIN' && (
                                        <td className='flex  flex-col lg:flex-row gap-2' >
                                            <Button className="btn btn-sm" variant="warning" onClick={() => handleEditOpen(reservation)}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                            <Button variant="danger" onClick={() => handleDelete(reservation.categoryId)} className="w-full lg:w-auto btn btn-sm">
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}

            {/* Edit Modal */}
            <Modal show={showEditModal} onHide={handleEditClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Reservation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formCategoryName">
                            <Form.Label>Category Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="categoryName"
                                value={selectedReservation.categoryName || ''}
                                onChange={handleEditChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formCategoryPercentage">
                            <Form.Label>Category Percentage</Form.Label>
                            <Form.Control
                                type="number"
                                name="categoryPercentage"
                                value={selectedReservation.categoryPercentage || ''}
                                onChange={handleEditChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleEditClose}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={handleEditSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* New Reservation Modal */}
            <Modal show={showNewModal} onHide={handleNewClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New Reservation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formCategoryName">
                            <Form.Label>Category Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="categoryName"
                                value={newReservation.categoryName || ''}
                                onChange={handleNewChange}
                                isInvalid={!!newReservationErrors.categoryName}
                            />
                            <Form.Control.Feedback type="invalid">
                                {newReservationErrors.categoryName}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formCategoryPercentage">
                            <Form.Label>Category Percentage</Form.Label>
                            <Form.Control
                                type="number"
                                name="categoryPercentage"
                                value={newReservation.categoryPercentage || ''}
                                onChange={handleNewChange}
                                isInvalid={!!newReservationErrors.categoryPercentage}
                            />
                            <Form.Control.Feedback type="invalid">
                                {newReservationErrors.categoryPercentage}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleNewClose}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={handleNewSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer />
        </div>
    );
};

export default VerticalReservationList;
