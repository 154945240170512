import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AuthProvider from "../utils/AuthProvider";
import { toast } from 'react-toastify';
import ENV from "../utils/Constant";
import { useNavigate, useParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { ArrowLeftCircle } from "react-bootstrap-icons";

const ReservationTable = () => {
    const [reservationData, setReservationData] = useState([]);
    const [designationName, setDesignationName] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const apiUrl = ENV.baseUrl;
    const { designationId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchReservationData = async () => {
            try {
                const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider
                // Fetch reservation data
                const response = await axios.get(`${apiUrl}/reservations/calculate-reservation/${designationId}`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });

                setReservationData(response.data);
                // console.log("res", response);

                // Fetch designation name
                const designationResponse = await axios.get(`${apiUrl}/designations/designationName/${designationId}`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });

                setDesignationName(designationResponse.data); // Assuming the response has a `name` field

                toast.success('Reservation data fetched successfully!');
            } catch (error) {
                console.error('Error fetching reservation data:', error.response ? error.response.data : error.message);
                setError('Error fetching reservation data. Please try again.');
                toast.error('Error fetching reservation data. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchReservationData();
    }, [designationId, apiUrl]);

    const handleBackClick = () => {
        navigate(-1); // Navigate back in history
    };

    const renderTableRows = () => {
        let serialNo = 1;
        let totalPosts = 0;  // To keep track of total "No. of Post"
        let totalCarryForward = 0;  // To keep track of total "Carry Forward"

        if (!reservationData || reservationData.length === 0) {
            return (
                <tr>
                    <td colSpan="4">No data available</td>
                </tr>
            );
        }

        // Initialize an array to collect all rows
        const rows = [];

        // Process reserved and general seats first
        reservationData.forEach((item, index) => {
            const {
                "Category Name": categoryName,
                "Reserved Seats": reservedSeats = 0,
                "General Seats": generalSeats = 0
            } = item;

            // Handle reserved seats
            if (reservedSeats > 0) {
                totalPosts += reservedSeats;
                rows.push(
                    <tr key={`${index}-reserved`}>
                        <td>{serialNo++}</td>
                        <td>{`Unreserved (${categoryName})`}</td>
                        <td>{reservedSeats}</td>
                        <td>0</td> {/* Placeholder for carry_forward */}
                    </tr>
                );
            }
        });

        reservationData.forEach((item, index) => {
            const {
                "Category Name": categoryName,
                "General Seats": generalSeats = 0
            } = item;

            // Handle general seats
            if (generalSeats > 0) {
                totalPosts += generalSeats;
                rows.push(
                    <tr key={`${index}-general`}>
                        <td>{serialNo++}</td>
                        <td>{`${categoryName} (General)`}</td>
                        <td>{generalSeats}</td>
                        <td>0</td> {/* Placeholder for carry_forward */}
                    </tr>
                );
            }
        });

        // Process horizontal reservations next
        reservationData.forEach((item) => {
            const horizontalReservations = item["Horizontal Reservations"] || [];

            horizontalReservations.forEach((horizItem, hIndex) => {
                const reservedSeats = horizItem["Horizontal Reserved Seats"] || 0;
                const carryForward = horizItem["Carray Forward"] || 0;

                // Add to totals if reservedSeats or carryForward > 0
                if (reservedSeats > 0 || carryForward > 0) {
                    totalPosts += reservedSeats;
                    totalCarryForward += carryForward;

                    rows.push(
                        <tr key={`${item["Category Name"]}-${hIndex}`}>
                            <td>{serialNo++}</td>
                            <td>{`${item["Category Name"]} (${horizItem["Category Name"]})`}</td>
                            <td>{reservedSeats}</td>
                            <td>{carryForward}</td>
                        </tr>
                    );
                }
            });
        });

        // Add a row for the totals at the end
        rows.push(
            <>
                <tr key="totals">
                    <td colSpan="2" style={{ fontWeight: 'bold' }}>Total</td>
                    <td style={{ fontWeight: 'bold' }}>{totalPosts}</td>
                    <td style={{ fontWeight: 'bold' }}>{totalCarryForward}</td>
                </tr>
                <tr key="totals">
                    <td colSpan="2" style={{ fontWeight: 'bold' }}>Total</td>
                    <td style={{ fontWeight: 'bold' }}>Actual Post + Carry forward</td>
                    <td style={{ fontWeight: 'bold' }}>{totalPosts + totalCarryForward}</td>
                </tr>
            </>
        );

        return rows;
    };

    // Carray Forward
    return (
        < div className="md:p-4">
            <div className='flex  flex-col gap-2  mb-4 pt-3 md:pt-0'>
                <button onClick={handleBackClick} className='max-w-32 mb-2 flex items-center gap-2 rounded-md bg-success text-white px-3 py-2 cursor-pointer'>
                    <ArrowLeftCircle /> Go Back
                </button>
                <h2 className='text-xl'>{designationName}</h2>

            </div>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (

                <div className='bg-white p-2 max-w-3xl rounded-md shadow-md' style={{ overflowX: 'auto' }}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Sl. No</th>
                                <th>Reservation Category</th>
                                <th>Actual Post</th>
                                <th>Carry forward</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTableRows()}
                        </tbody>
                    </Table>
                </div>
            )}
        </div>
    );
};

export default ReservationTable;
