import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AuthProvider from "../utils/AuthProvider";
import { toast } from 'react-toastify';
import ENV from "../utils/Constant";
import { useNavigate, useParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { ArrowLeftCircle } from "react-bootstrap-icons";
import { useLocation } from 'react-router-dom';

const VacancyFormResults = () => {
    const location = useLocation();
    const { newvacancy, vacancy, carryForwardsCount, totalDesignationSanctionedStrength } = location.state || {};
    console.log(newvacancy, vacancy, carryForwardsCount, totalDesignationSanctionedStrength);

    const [reservationData, setReservationData] = useState([]);
    const [designationName, setDesignationName] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const apiUrl = ENV.baseUrl;
    const { designationId } = useParams();
    const navigate = useNavigate();


    const handleBackClick = () => {
        navigate(-1); // Navigate back in history
    };


    // Carray Forward
    return (
        < div className="md:p-4">
            <div className='flex  flex-col gap-2  mb-4 pt-3 md:pt-0'>
                <button onClick={handleBackClick} className='max-w-32 mb-2 flex items-center gap-2 rounded-md bg-success text-white px-3 py-2 cursor-pointer'>
                    <ArrowLeftCircle /> Go Back
                </button>
                <h2 className='text-xl'>{designationName}</h2>

            </div>
            {/* {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : ( */}

            <div className='bg-white p-2 max-w-3xl rounded-md shadow-md' style={{ overflowX: 'auto' }}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Entered Vacancy</th>
                            <th>Actual Vacancy</th>
                            <th>Carry Forwards Count</th>
                            <th>Total Designation Sanctioned Strength</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr key="totals">
                            <td style={{ fontWeight: 'bold' }}>{newvacancy}</td>
                            <td style={{ fontWeight: 'bold' }}>{vacancy}</td>
                            <td style={{ fontWeight: 'bold' }}>{carryForwardsCount}</td>
                            <td style={{ fontWeight: 'bold' }}>{totalDesignationSanctionedStrength}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            {/* )} */}
        </div>
    );
};

export default VacancyFormResults;
