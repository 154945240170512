import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import VerticalReservation from "./components/VerticalReservation";
import HorizontalReservation from "./components/HorizontalReservation";
import Designation from "./components/Designation";
import Vacancy from "./components/Vacancy";
import CadreList from "./components/CadreList";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Dashboard from "./components/Dashboard";
import ReservationResult from "./components/ReservationResult";
import User from "./components/user/User";
import Login from "./components/user/Login";
import { Button } from "react-bootstrap";
import { GiHamburgerMenu } from "react-icons/gi";
import useRoleStore from './utils/helpers/store/zustand';
import CarryForwardList from './components/CarryForwardList';
import CadreCalculations from './components/CadreCalculations';
import ReservationTable from './components/ReservationFormResults';
import VacancyCalculations from './components/SeatAllocation';
import VacancyFormResults from './components/VacancyFormResults';
import SeatAllocationResult from './components/SeatAllocationResult';

function App() {
    const [showSidebar, setShowSidebar] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true');
    const [UserRole, setUserRole] = useState('');

    const handleShowSidebar = () => setShowSidebar(true);
    const handleCloseSidebar = () => setShowSidebar(false);
    const Userroles = localStorage.getItem('role')

    return (
        <Router>
            {isAuthenticated ? (
                <div className='flex'>
                    <div className=' lg:static'>
                        <Sidebar setIsAuthenticated={setIsAuthenticated} show={showSidebar} handleClose={handleCloseSidebar} className='' />
                    </div>
                    <div className='flex flex-col text-black w-full'>
                        <div className='flex items-center'>
                            <div className='py-2 lg:hidden'>
                                <button onClick={handleShowSidebar} className="border-2  border-slate-200 p-2 ">
                                    <GiHamburgerMenu className='fill-black text-black size-7' />
                                </button>
                            </div>
                            <Header setIsAuthenticated={setIsAuthenticated} />
                        </div>
                        <div className={` ${showSidebar ? 'shifted' : ''} px-2 w-full h-full bg-slate-200 lg:rounded-tl-2xl`}>
                            <Routes>
                                <Route path="/vertical-reservation" element={<VerticalReservation />} />
                                <Route path="/horizontal-reservation" element={<HorizontalReservation />} />
                                <Route path="/cadre" element={<CadreList />} />
                                <Route path="/designation" element={<Designation />} />
                                <Route path="/designations/:cadreId" element={<Designation />} />
                                <Route path="/carry-forward/:Id" element={<CarryForwardList />} />
                                <Route path="/vacancy" element={<Vacancy />} />
                                <Route path="/reservation/:designationId" element={<ReservationResult />} />
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/reservation-calculations" element={<CadreCalculations />} />
                                <Route path="/vacancy-calculations" element={<VacancyCalculations />} />
                                <Route path="/reservation-calculations/:designationId" element={<ReservationTable />} />
                                <Route path="/vacancy-calculations/:designationId" element={<VacancyFormResults />} />
                                <Route path="/seat-allocation-result" element={<SeatAllocationResult />} />
                                {Userroles === 'ROLE_ADMIN' ?
                                    <Route path="/user" element={<User />} />
                                    :
                                    <Route path="/" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                                }
                                <Route path="*" element={<Navigate to="/dashboard" replace />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            ) : (
                <Routes>
                    <Route path="/" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                    <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                    <Route path="*" element={<Navigate to="/login" replace />} />
                </Routes>
            )}
        </Router>
    );
}

export default App;
