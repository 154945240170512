import store from 'store-js';
import { jwtDecode } from 'jwt-decode'; // Correct the import

const AuthProvider = {
    isAuthenticated() {
        const tokenObj = store.get('token');
        if (tokenObj !== undefined && tokenObj !== null) {
            if (tokenObj.expireAt && new Date(tokenObj.expireAt) < new Date()) {
                store.remove('token');
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    },
    authenticate(cb) {
        cb();
    },
    signout(cb) {
        store.remove('token');
        cb();
    },
    logout() {
        store.remove('token');
    },
    getToken() {
        const tokenObj = store.get('token');
        return tokenObj ? tokenObj.access_token : null;
    },
    handleLoginSuccess(response, setIsAuthenticated) {
        const accessToken = response.data.token || null;

        if (!accessToken) {
            console.error('Access token missing in response.');
            return;
        }

        let expireAt = null;
        try {
            const decodedToken = jwtDecode(accessToken);
            if (decodedToken && decodedToken.exp) {
                expireAt = new Date(decodedToken.exp * 1000); // Convert to milliseconds
            }
        } catch (error) {
            console.error('Error decoding token:', error);
        }

        if (!expireAt) {
            console.error('Expiration time missing in token.');
            return;
        }

        const tokenObj = {
            access_token: accessToken,
            expireAt: expireAt,
        };
        store.set('token', tokenObj);
        setIsAuthenticated(true);
        localStorage.setItem("isAuthenticated", true);
    }
};

export default AuthProvider;
