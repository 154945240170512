import { useState, useEffect } from 'react';
import axios from 'axios';
import AuthProvider from "../utils/AuthProvider";
import ENV from "../utils/Constant";

const useHorizontalReservationData = () => {
    const [data, setData] = useState({ totalPercentage: 0, remainingPercentage: 0 });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const apiUrl = ENV.baseUrl;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

                const response = await axios.get(`${apiUrl}/horizontal-reservations/percentages`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });

                setData({
                    totalPercentage: response.data.totalPercentage,
                    remainingPercentage: response.data.remainingPercentage
                });
                setLoading(false);
            } catch (error) {
                setError('Error fetching horizontal reservation data. Please try again.');
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]);

    return { data, loading, error };
};

export default useHorizontalReservationData;
