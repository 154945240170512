import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container, Typography, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, CircularProgress, IconButton, Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    TextField, Snackbar, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { Edit, Delete, Add } from '@mui/icons-material';
import Alert from '@mui/material/Alert';

const VacancyList = () => {
    const [vacancies, setVacancies] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openNewModal, setOpenNewModal] = useState(false);
    const [selectedVacancy, setSelectedVacancy] = useState({
        vacancyId: '',
        designation: { designationId: '', designationName: '' },
        totalVacancy: ''
    });
    const [newVacancy, setNewVacancy] = useState({
        vacancyId: '',
        designation: { designationId: '', designationName: '' },
        totalVacancy: ''
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        const fetchVacancies = async () => {
            setLoading(true);
            try {
                const response = await axios.get('http://localhost:8081/sk-roster/api/vacancy');
                setVacancies(response.data);
                setLoading(false);
                setError(null);
            } catch (error) {
                console.error('Error fetching vacancies:', error);
                setError('Error fetching vacancies. Please try again.');
                setLoading(false);
            }
        };

        const fetchDesignations = async () => {
            try {
                const response = await axios.get('http://localhost:8081/sk-roster/api/designations');
                setDesignations(response.data);
            } catch (error) {
                console.error('Error fetching designations:', error);
            }
        };

        fetchVacancies();
        fetchDesignations();
    }, []);

    const handleEditOpen = (vacancy) => {
        setSelectedVacancy(vacancy);
        setOpenEditModal(true);
    };

    const handleEditClose = () => {
        setOpenEditModal(false);
    };

    const handleNewOpen = () => {
        setOpenNewModal(true);
    };

    const handleNewClose = () => {
        setOpenNewModal(false);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setSelectedVacancy({ ...selectedVacancy, [name]: value });
    };

    const handleNewChange = (e) => {
        const { name, value } = e.target;
        setNewVacancy({ ...newVacancy, [name]: value });
    };

    const handleEditSubmit = async () => {
        try {
            await axios.put(`http://localhost:8081/sk-roster/api/vacancy/edit/${selectedVacancy.vacancyId}`, selectedVacancy);
            setVacancies(vacancies.map(vacancy => vacancy.vacancyId === selectedVacancy.vacancyId ? selectedVacancy : vacancy));
            handleEditClose();
            showSnackbar('Vacancy updated successfully', 'success');
        } catch (error) {
            console.error('Error updating vacancy:', error);
            showSnackbar('Error updating vacancy. Please try again.', 'error');
        }
    };

    const handleNewSubmit = async () => {
        try {
            const response = await axios.post('http://localhost:8081/sk-roster/api/vacancy/create-vacancy', newVacancy);
            setVacancies([...vacancies, response.data]);
            handleNewClose();
            showSnackbar('New vacancy created successfully', 'success');
        } catch (error) {
            console.error('Error creating new vacancy:', error);
            showSnackbar('Error creating new vacancy. Please try again.', 'error');
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`http://localhost:8081/sk-roster/api/vacancy/delete/${id}`);
            setVacancies(vacancies.filter(vacancy => vacancy.vacancyId !== id));
            showSnackbar('Vacancy deleted successfully', 'success');
        } catch (error) {
            console.error('Error deleting vacancy:', error);
            showSnackbar('Error deleting vacancy. Please try again.', 'error');
        }
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container>
            <Typography variant="h6" gutterBottom>
                List of Vacancies
            </Typography>
            <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleNewOpen} sx={{ mb: 2 }}>
                New Vacancy
            </Button>
            {loading ? (
                <CircularProgress />
            ) : error ? (
                <Typography color="error">{error}</Typography>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Vacancy ID</TableCell>
                                <TableCell>Designation ID</TableCell>
                                <TableCell>Designation Name</TableCell>
                                <TableCell>Total Vacancy</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {vacancies.map((vacancy) => (
                                <TableRow key={vacancy.vacancyId}>
                                    <TableCell>{vacancy.vacancyId}</TableCell>
                                    <TableCell>{vacancy.designation.designationId}</TableCell>
                                    <TableCell>{vacancy.designation.designationName}</TableCell>
                                    <TableCell>{vacancy.totalVacancy}</TableCell>
                                    <TableCell align="right">
                                        <IconButton color="primary" onClick={() => handleEditOpen(vacancy)}>
                                            <Edit />
                                        </IconButton>
                                        <IconButton color="secondary" onClick={() => handleDelete(vacancy.vacancyId)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* Snackbar for notifications */}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            {/* Edit Modal */}
            <Dialog open={openEditModal} onClose={handleEditClose}>
                <DialogTitle>Edit Vacancy</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Edit the details of the vacancy.
                    </DialogContentText>
                    <TextField
                        margin="dense"
                        label="Vacancy ID"
                        name="vacancyId"
                        value={selectedVacancy.vacancyId}
                        onChange={handleEditChange}
                        fullWidth
                        disabled
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Designation</InputLabel>
                        <Select
                            name="designationId"
                            value={selectedVacancy.designation.designationId}
                            onChange={(e) =>
                                setSelectedVacancy({
                                    ...selectedVacancy,
                                    designation: {
                                        ...selectedVacancy.designation,
                                        designationId: e.target.value,
                                        designationName: designations.find(d => d.designationId === e.target.value)?.designationName || ''
                                    }
                                })
                            }
                        >
                            {designations.map((designation) => (
                                <MenuItem key={designation.designationId} value={designation.designationId}>
                                    {designation.designationName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Total Vacancy"
                        name="totalVacancy"
                        value={selectedVacancy.totalVacancy}
                        onChange={handleEditChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleEditSubmit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* New Vacancy Modal */}
            <Dialog open={openNewModal} onClose={handleNewClose}>
                <DialogTitle>New Vacancy</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the details for the new vacancy.
                    </DialogContentText>
                    <TextField
                        margin="dense"
                        label="Vacancy ID"
                        name="vacancyId"
                        value={newVacancy.vacancyId}
                        onChange={handleNewChange}
                        fullWidth
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Designation</InputLabel>
                        <Select
                            name="designationId"
                            value={newVacancy.designation.designationId}
                            onChange={(e) =>
                                setNewVacancy({
                                    ...newVacancy,
                                    designation: {
                                        ...newVacancy.designation,
                                        designationId: e.target.value,
                                        designationName: designations.find(d => d.designationId === e.target.value)?.designationName || ''
                                    }
                                })
                            }
                        >
                            {designations.map((designation) => (
                                <MenuItem key={designation.designationId} value={designation.designationId}>
                                    {designation.designationName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Total Vacancy"
                        name="totalVacancy"
                        value={newVacancy.totalVacancy}
                        onChange={handleNewChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNewClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleNewSubmit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default VacancyList;
