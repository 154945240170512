import React, { useEffect, useState } from 'react';
import { Table,Button } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthProvider from '../utils/AuthProvider';
import ENV from '../utils/Constant';

const SeatAllocationResult = () => {
    const location = useLocation();
    const { results } = location.state || {};
    const navigate = useNavigate();
    const [reservationData, setReservationData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const apiUrl = ENV.baseUrl;
    console.log(results);

    // Update reservationData when results are available
    useEffect(() => {
        if (results) {
            setReservationData(results); // Set the results to reservationData state
            console.log("reservationData",reservationData);
            setLoading(false);
        }
    }, [results]); 

    const renderTableRows = () => {
        let serialNo = 1;
        let totalPosts = 0;  // To keep track of total "No. of Post"
        let totalCarryForward = 0;  // To keep track of total "Carry Forward"

        if (!reservationData || reservationData.length === 0) {
            return (
                <tr>
                    <td colSpan="4">No data available</td>
                </tr>
            );
        }

        // Initialize an array to collect all rows
        const rows = [];

        // Process reserved and general seats first
        reservationData.forEach((item, index) => {
            const {
                "Category Name": categoryName,
                "Reserved Seats": reservedSeats = 0,
                "General Seats": generalSeats = 0
            } = item;

            // Handle reserved seats
            if (reservedSeats > 0) {
                totalPosts += reservedSeats;
                rows.push(
                    <tr key={`${index}-reserved`}>
                        <td>{serialNo++}</td>
                        <td>{`Unreserved (${categoryName})`}</td>
                        <td>{reservedSeats}</td>
                        <td>0</td> {/* Placeholder for carry_forward */}
                    </tr>
                );
            }
        });

        reservationData.forEach((item, index) => {
            const {
                "Category Name": categoryName,
                "General Seats": generalSeats = 0
            } = item;

            // Handle general seats
            if (generalSeats > 0) {
                totalPosts += generalSeats;
                rows.push(
                    <tr key={`${index}-general`}>
                        <td>{serialNo++}</td>
                        <td>{`${categoryName} (General)`}</td>
                        <td>{generalSeats}</td>
                        <td>0</td> {/* Placeholder for carry_forward */}
                    </tr>
                );
            }
        });

        // Process horizontal reservations next
        reservationData.forEach((item) => {
            const horizontalReservations = item["Horizontal Reservations"] || [];

            horizontalReservations.forEach((horizItem, hIndex) => {
                const reservedSeats = horizItem["Horizontal Reserved Seats"] || 0;
                const carryForward = horizItem["Carray Forward"] || 0;

                // Add to totals if reservedSeats or carryForward > 0
                if (reservedSeats > 0 || carryForward > 0) {
                    totalPosts += reservedSeats;
                    totalCarryForward += carryForward;

                    rows.push(
                        <tr key={`${item["Category Name"]}-${hIndex}`}>
                            <td>{serialNo++}</td>
                            <td>{`${item["Category Name"]} (${horizItem["Category Name"]})`}</td>
                            <td>{reservedSeats}</td>
                            <td>{carryForward}</td>
                        </tr>
                    );
                }
            });
        });

        // Add a row for the totals at the end
        rows.push(
            <>
                <tr key="totals">
                    <td colSpan="2" style={{ fontWeight: 'bold' }}>Total</td>
                    <td style={{ fontWeight: 'bold' }}>{totalPosts}</td>
                    <td style={{ fontWeight: 'bold' }}>{totalCarryForward}</td>
                </tr>
                <tr key="totals">
                    <td colSpan="2" style={{ fontWeight: 'bold' }}>Total</td>
                    <td style={{ fontWeight: 'bold' }}>Actual Post + Carry forward</td>
                    <td style={{ fontWeight: 'bold' }}>{totalPosts + totalCarryForward}</td>
                </tr>
            </>
        );

        return rows;
    };


    const handleNextReservation = async () => {

        try {
            const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

            const response = await axios.delete(`${apiUrl}/carry-forward/delete-all-carry-forward-vacancies`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                }
            });
            console.log("response", response.data);
        } catch (error) {
            console.error('Error fetching vertical reservations:', error);
            setError('Error fetching vertical reservations. Please try again.');
        }

        navigate('/dashboard');
    };

    // Carray Forward
    return (
        < div className="md:p-4">
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (

                <div className='bg-white p-2 max-w-3xl rounded-md shadow-md' style={{ overflowX: 'auto' }}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Sl. No</th>
                                <th>Reservation Category</th>
                                <th>Actual Post</th>
                                <th>Carry forward</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTableRows()}
                        </tbody>
                    </Table>
                    <Button variant="success" onClick={handleNextReservation} className="mt-3">
                        Calculate Another Reservation
                    </Button>
                </div>
            )}
        </div>
    );
};

export default SeatAllocationResult;